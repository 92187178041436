import { tagAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tagAnatomy.keys)

const baseStyle = definePartsStyle({
  // define the part you're going to style
  container: {
    bg: 'orange',
    color: 'white',
    fontWeight: '600',
    padding: '0.2em 0.6em',
    fontWeight: 'normal',
    height: "1.4em",
    borderRadius: '3px',
  },
})

const variants = {
  system: {
    container: {
      bg: 'blue.600',
    }
  },
  ugc: {
    container: {
      bg: 'white',
      color: 'navy',
    }
  }
};

export const tagTheme = defineMultiStyleConfig({ baseStyle, variants })
