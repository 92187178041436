import {
  React,
  useState,
  useRef,
} from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Text,
} from '@chakra-ui/react';

import { useNavigate, useParams, Link } from "react-router-dom";
import { useQuery } from 'react-query'
import api from "../utils/api";
import { FaMapPin } from "react-icons/fa";
import useUserState from "../stores/user";
import Header from '../components/Header';
import Error from '../components/Error';
import GameDetails from "../components/GameDetails";
import ZonePicker from '../components/ZonePicker'


import GoogleMap from 'google-maps-react-markers'
const Marker = ({ label }) => <HStack style={{ marginLeft: '-24px', marginTop: '-24px' }}>
  <Icon as={FaMapPin} color="orange" w="36px" h="36px" />
  <Text bg="muted" style={{ whiteSpace: 'nowrap', fontSize: '24px', borderRadius: '24px', padding: '4px 10px 4px 10px' }}>{label}</Text>
</HStack>



const GamePage = ({ }) => {
  const navigate = useNavigate();
  const usertmp = useUserState((state) => state.user);
  const user = usertmp || {};

  const [joinError, setJoinError] = useState(null);
  const { gameId } = useParams();
  const gameQuery = useQuery(['game', gameId], () => api('/games/' + gameId));
  const game = gameQuery.data;
  const playerRecord = game?.players.filter((p) => p.id == user.id);
  const myGame = game?.host.id == user.id;

  const mapLocation = !game || game?.online ?
    null :
    (game.venueId ?
      (game.venue.lat ? { lat: parseFloat(game.venue.lat), lng: parseFloat(game.venue.lon) } : null) :
      (game.lat ? { lat: parseFloat(game.lat), lng: parseFloat(game.lon) } : null));

  const role = myGame ? "owner" :
    (playerRecord?.length ?
      (playerRecord[0].approved ? "player" : "pending") :
      "none");

  const joinGame = async () => {
    const res = await api('games/' + gameId + '/join', {}, 'POST');
    gameQuery.refetch();
    if (res.error) {
      setJoinError(res.error);
    }
  };

  const deleteGame = async () => {
    if (window.confirm('Press OK if you really want to delete this game and all its occurences.')) {
      await api('games/' + gameId, {}, 'DELETE');
      navigate('/');
    }
  }

  const leaveGame = async () => {
    await api('games/' + gameId + '/join', {}, 'DELETE');
    gameQuery.refetch();
  }



  return <Box>
    <Header back={user.id ? -1 : null} rightSide={<HStack>

    </HStack>} />
    <Flex direction={'column'} w={'100%'} p={8} alignItems={'center'}>

      <HStack justify="space-between" alignItems="center" mb={4}>
        {(role == "owner" || user.admin) && <Button onClick={() => navigate('/games/' + (game?.parentId ? game.parentId : gameId) + '/edit')} variant="outline" >Edit</Button>}
        {(role == "owner") && <Button onClick={() => navigate('/games/' + (game?.parentId ? game.parentId : gameId) + '/dupe')} variant="outline" colorScheme="green">Duplicate</Button>}
        {(role == "owner") && <Button onClick={() => deleteGame()} variant="outline" colorScheme="red" >Delete</Button>}
      </HStack>

      {game && <GameDetails game={game} />}

      {joinError && <Error>{joinError}</Error>}
      {(role == "none" && game?.players.length < game?.maxPlayers) && <Button onClick={joinGame} w="50%" my={6}>Request to Join</Button>}
      {(role == "none" && game?.players.length < game?.maxPlayers && !user.trustxVerified) && user.id && false && <Button onClick={() => navigate('/account')} w="50%" my={6}>Get Verified to join a Game</Button>}

      {role == "pending" ? <Button disabled w="50%" my={6} variant="disabled">Request Pending</Button> : null}

      {((role == "owner" || role == "player") && game?.maxPlayers > 0) && <Button onClick={() => navigate('/games/' + gameId + '/players')} w="50%" my={6}>Attendees List</Button>}
      {(role == "player") && <Button onClick={leaveGame} w="50%" my={6} variant="secondary">Leave Game</Button>}

      {mapLocation && (role == 'owner' || role == 'player') && <Box w="100%" mt={6}>
        <GoogleMap
          apiKey={process.env.REACT_APP_MAPS_KEY}
          defaultCenter={mapLocation}
          defaultZoom={14}
          mapMinHeight="600px"
        >
          <Marker
            lat={mapLocation.lat}
            lng={mapLocation.lng}
            label={game.venueId ? game.venue.name : game.locationAddress}
            markerId={1}
            key={1}
          />
        </GoogleMap>
      </Box>}

    </Flex>

    <ZonePicker />
  </Box>
}



export default GamePage;
