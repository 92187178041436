
const tagSort = (a, b) => {
  var order = ['system','preference','ugc'];
  var aval = order.indexOf(a.variant);
  var bval = order.indexOf(b.variant);
  if (aval == -1) aval = 2;
  if (bval == -1) bval = 2;
  return aval==bval ? 0 : (aval > bval ? 1 : -1);
  
};

export  {
  tagSort,
}
